import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTermsOnLocalStorage, debounce } from '../../utils';
import { removeDotsFromQueryStart, isValidQuery, sanitizedQuery } from '../../utils/common';
import {
  fetchSearchTerms,
  searchQuery,
  clearSearch,
  suggestionClick,
  fetchTermResult,
  toggleDropdown,
} from '../../actions';

const magentoSearchInputRef = document.getElementById('search');
const searchForm = document.getElementById('search_mini_form');
const searchButton = searchForm.querySelector('button[type="submit"]');

const SearchInput = ({ isFocused, isSearchFullType, setShowMainLoaderMask }) => {
  const {
    showDropdown,
    isSearching,
    suggestions: { activeTerm, fetching, redirects },
  } = useSelector((state) => state);
  const redirect = redirects && redirects[activeTerm] ? redirects[activeTerm] : null;
  const dispatch = useDispatch();
  const searchInputRef = useRef();
  const [inputVal, setInputVal] = useState('');

  useEffect(() => {
    if (isFocused) searchInputRef?.current?.focus();
  }, [isFocused]);

  const makeSearchCall = (searchText) => {
    const searchLabel = document.getElementById('minisearch-label');
    const searchControl = document.getElementById('minisearch-control');
    const rightCol = document.getElementsByClassName('col-right');

    if (searchForm && !searchForm.classList.contains('opened')) {
      searchForm?.classList?.add('opened');
      searchLabel?.classList?.add('opened');
      searchControl?.classList?.add('opened');
    }

    if (rightCol.length) {
      rightCol[0].style.display = 'none';
    }

    dispatch(searchQuery(searchText));
    let query = searchText || '';
    if (!query || query.length < 2) {
      dispatch(clearSearch());
    } else {
      dispatch(clearSearch())
        .then(() => dispatch(searchQuery(query)))
        .then(() => dispatch(fetchSearchTerms(query)))
        .then(() => dispatch(suggestionClick(query)))
        .then(() => dispatch(fetchTermResult(query, true)));
    }
  };

  const handleInputChange = debounce((value) => {
    makeSearchCall(value);
  }, 500);

  const handleChange = (event) => {
    const newValue = event.target.value;
    let finalValue = '';
    if (isValidQuery(newValue)) {
      finalValue = newValue;
    } else {
      const sanitizedValue = sanitizedQuery(newValue);
      finalValue = sanitizedValue;
    }

    if (magentoSearchInputRef) {
      magentoSearchInputRef.value = finalValue;
    }
    setInputVal(finalValue);
    handleInputChange(finalValue);
  };

  const handleClick = () => {
    searchInputRef?.current?.focus();
  };

  const handleSubmitClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (inputVal && inputVal?.length >= 2) {
      setShowMainLoaderMask?.(true);
      redirectOnSearchPage();
    }
  };

  const redirectOnSearchPage = () => {
    const term = magentoSearchInputRef?.value || inputVal || '';
    if (term) {
      const withoutDotsTerm = removeDotsFromQueryStart(term);
      let url = encodeURIComponent(withoutDotsTerm)
        ? `/catalogsearch/result/?q=${encodeURIComponent(withoutDotsTerm)}`
        : '';
      if (url) {
        setTermsOnLocalStorage(withoutDotsTerm);
        window.location.href = url;
        return;
      }
    }
  };

  const handleInputFocus = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (activeTerm && !showDropdown) dispatch(toggleDropdown(true));
  };

  useEffect(() => {
    magentoSearchInputRef?.addEventListener('input', handleChange);

    return () => {
      if (magentoSearchInputRef) {
        magentoSearchInputRef?.removeEventListener('input', handleChange);
        magentoSearchInputRef.removeEventListener('click', handleInputFocus, false);
        searchForm.removeEventListener('submit', handleSubmitClick);
        searchButton?.removeEventListener('click', handleSubmitClick);
      }
    };
  }, []);

  useEffect(() => {
    magentoSearchInputRef.addEventListener('click', handleInputFocus);
  }, [activeTerm, showDropdown]);

  useEffect(() => {
    searchForm?.addEventListener('submit', handleSubmitClick);
    searchButton?.addEventListener('click', handleSubmitClick);
  }, [inputVal, isSearching, fetching, activeTerm, redirect]);

  return isSearchFullType ? (
    <>
      <input
        ref={searchInputRef}
        className="input-text"
        type="text"
        name="search"
        value={inputVal}
        placeholder="Find your tools..."
        maxLength="128"
        role="combobox"
        onChange={handleChange}
        onClick={handleClick}
      />
      <div className="actions">
        <button type="submit" className="action search" onClick={(e) => handleSubmitClick(e)}>
          search
        </button>
      </div>
    </>
  ) : null;
};

export default SearchInput;
