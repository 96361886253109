import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from './reducers/index';

const enableChromeExtention = true;

const composeEnhancers = enableChromeExtention ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export default function configureStore(initialState) {
  return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunkMiddleware)));
}
