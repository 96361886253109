import React from "react";
import { HAMMER_SPINNER_BASE64 } from "../utils/constants";
import { useSelector } from "react-redux";
export const MainLoader = ({showMainLoaderMask}) => {
    const showMainLoader = useSelector((state) => state.showMainLoader);
    return showMainLoader || showMainLoaderMask ? (
        <div className="loading-mask" data-role="loader">
            <div className="loader">
                <img alt="Loading..." src={HAMMER_SPINNER_BASE64} />
                <p>Please wait...</p>
            </div>
        </div>
    ) : null;
};
