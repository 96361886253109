import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Products from './products';
import Corrections from '../../components/auto-suggest/no-result/corrections';
import HammerSpinner from '../../utils/HammerSpinner';
import { fetchCorrectionTermResult } from '../../actions';

const NoResult = ({ show, activeTerm }) => {
  const {
    isSearching,
    corrections: { products, terms: corrections, isFetchingProducts: isLoadingProducts },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (corrections && corrections[0]) {
      dispatch(fetchCorrectionTermResult(corrections[0]));
    }
  }, [corrections?.length, dispatch]);

  return show && activeTerm ? (
    <div className="search-autocomplete-no-result">
      <p>
        No results found for <strong>{`"${activeTerm}"`}</strong>
      </p>
      {!isSearching ? <Corrections /> : null}
      <div style={{ marginTop: '10px' }}>
        <HammerSpinner show={isLoadingProducts} />
        <Products products={products} />
      </div>
    </div>
  ) : null;
};

export default NoResult;
