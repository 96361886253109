import React from "react";
import { setTermsOnLocalStorage } from "../../utils";
import { useDispatch } from "react-redux";
import { toggleMainLoader } from "../../actions";
import Product from "./product/product";

const Products = ({ products, activeTerm, redirect }) => {
    const formRef = document.getElementById("search_mini_form");
    const dispatch = useDispatch();

    const renderProducts = (prods) => {
        return prods.map((prod, idx) => {
            return <Product key={idx} position={idx + 1} data={prod} />;
        });
    };

    const handleClick = () => {
        let url = `/catalogsearch/result/?q=${encodeURIComponent(activeTerm)}`;

        if (redirect) {
            url = redirect;
        }
        dispatch(toggleMainLoader(true));
        setTermsOnLocalStorage(activeTerm);
        window.location.href = url;
    };

    return products && products.length > 0 ? (
        <div className="search-autocomplete-products">
            <h5>search results</h5>
            {activeTerm && (
                <div
                    className={`search-autocomplete-more${
                        products.length < 5 ? " hidden" : ""
                    }`}
                >
                    <a
                        className="button button-primary__outline"
                        onClick={handleClick}
                    >
                        View all results for <span>{`"${activeTerm}"`}</span>
                    </a>
                </div>
            )}
            <div>{renderProducts(products)}</div>
        </div>
    ) : null;
};

export default Products;
