import { KEYWORD_SUGGESTION, IN_FIELD, TOP_SEARCH_QUERIES } from './constants';
import { STORES_ENUM, PRICE_FIELD, SPECIAL_PRICE_FIELD, ORIGINAL_PRICE_FIELD } from '../constants/api';

export const prepareAutoCompleteResults = (payload) => {
  const allProducts = payload?.response?.products;
  const metaData = payload?.searchMetaData;
  const spellCorrections = payload?.didYouMean && [payload?.didYouMean[0].suggestion];
  const products = allProducts?.filter((prod) => prod.hasOwnProperty('sku') && prod);
  const queryProductsCount = payload.response.numberOfProducts || 0;
  const autoSuggest = allProducts?.filter((prod) => prod?.doctype === KEYWORD_SUGGESTION && prod);
  const topSearchedQueries = allProducts?.filter((prod) => prod?.doctype === TOP_SEARCH_QUERIES && prod);
  const inFieldsSuggestion = allProducts?.filter((prod) => prod?.doctype === IN_FIELD && prod);
  const redirect = payload?.redirect?.value;
  return {
    metaData,
    products,
    queryProductsCount,
    autoSuggest: [...topSearchedQueries, ...autoSuggest],
    inFieldsSuggestion,
    spellCorrections,
    redirect,
  };
};

export const getProductPriceAttributes = (data) => {
  if (!data) return;
  const storeName = window?.unbxdConfig?.storeName;
  if (!storeName) {
    return { finalPrice: data?.price, storeSpecialPrice: data?.specialPrice, storeOriginalPrice: data?.originalPrice };
  }
  const storeId = STORES_ENUM[storeName];
  const storeField = `Store${storeId}`;
  const finalPrice = data?.[`${PRICE_FIELD}${storeField}`] || data?.[PRICE_FIELD];
  const storeSpecialPrice = data?.[`${SPECIAL_PRICE_FIELD}${storeField}`] || data?.[SPECIAL_PRICE_FIELD];
  const storeOriginalPrice = data?.[`${ORIGINAL_PRICE_FIELD}${storeField}`] || data?.[ORIGINAL_PRICE_FIELD];
  return { finalPrice, storeSpecialPrice, storeOriginalPrice };
};

export const formatPrice = (price = 0) => {
  let priceStr = price.toFixed(2).toString();

  if (priceStr.indexOf('.') === -1) {
    return price;
  }

  let priceArr = priceStr.split('.');

  if (priceArr.length > 1 && priceArr[1] !== '00') {
    price = priceArr;
  } else {
    price = priceArr[0];
  }

  return price;
};

export const removeDotsFromQueryStart = (query) => {
  const withoutDotsQuery = query?.replace(/^\.*/, '');
  return withoutDotsQuery;
};

export const isValidQuery = (query) => {
  const tagRegex = /<[^>]*?>|<\/[^>]+>|<[^>]*$|^[^<]*>/g;
  const tags = query.match(tagRegex);
  if (tags) {
    return false;
  }
  return true;
};

export const sanitizedQuery = (html) => {
  const tagAndTextRegex = /<[^>]*?>[^<]*|<\/[^>]+>|<[^>]*$|>[^<]*/g;
  const sanitizedText = html.replace(tagAndTextRegex, '');
  return sanitizedText.trim() === '' ? '' : sanitizedText.trim();
};
