import React from 'react';

const Error = ({ error, errorMsg, query }) => {
  const finalErrorMessage = errorMsg && errorMsg.replace('{{%q}}', query);

  return error && query ? (
    <div className="search-autocomplete-error">
      <p>
        <strong>{finalErrorMessage}</strong>
      </p>
    </div>
  ) : null;
};

export default Error;
