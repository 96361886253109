import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTermsOnLocalStorage, capitalize } from '../../../utils';
import { toggleMainLoader } from '../../../actions';

const Corrections = () => {
  const { terms: corrections, redirects } = useSelector((state) => state.corrections);
  const dispatch = useDispatch();
  const handleClick = (term, ev) => {
    ev.preventDefault();
    const capitalizeTerm = capitalize(term);

    const redirect = redirects && redirects[capitalizeTerm] ? redirects[capitalizeTerm] : null;
    let url;
    if (redirect) {
      url = redirect;
    } else {
      url = `/catalogsearch/result/?q=${encodeURIComponent(term)}`;
    }
    dispatch(toggleMainLoader(true));
    setTermsOnLocalStorage(term);
    window.location.href = url;
  };

  return corrections && corrections.length ? (
    <div className="search-autocomplete-corrections">
      <span className="ttl">Are you looking for:</span>
      {corrections?.map((corr, idx) => (
        <span className="opt" key={idx} onClick={(ev) => handleClick(corr, ev)}>
          {capitalize(corr)}
        </span>
      ))}
    </div>
  ) : null;
};

export default Corrections;
