import React from 'react';

const ProductsCount = ({ count }) => {
  return count > 0 ? (
    <div className="product-count">
      <span>{count}</span>
      <span>{count > 1 ? 'products' : 'product'}</span>
    </div>
  ) : null;
};

export default ProductsCount;
