export const SEARCH_QUERY = 'SEARCH_QUERY';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SEARCH_RESULT = 'SEARCH_RESULT';
export const SEARCH_RESULT_ERROR = 'SEARCH_RESULT_ERROR';
export const SEARCH_CORRECTIONS_STARTED = 'SEARCH_CORRECTIONS_STARTED';
export const SEARCH_CORRECTION_SUCCESS = 'SEARCH_CORRECTION_SUCCESS';
export const SEARCH_CORRECTIONS_ERROR = 'SEARCH_CORRECTIONS_ERROR';
export const CLEAR_ACTIVE_TERM = 'CLEAR_ACTIVE_TERM';
export const PRODUCTS_CLEAR = 'PRODUCTS_CLEAR';

export const SUGGEST_CLICK = 'SUGGEST_CLICK';
export const SUGGEST_REQUEST = 'SUGGEST_REQUEST';
export const SUGGEST_SUCCESS = 'SUGGEST_SUCCESS';
export const SUGGEST_FAILURE = 'SUGGEST_FAILURE';
export const TOGGLE_DROPDOWN = 'TOGGLE_DROPDOWN';
export const UPDATE_LS_PRODUCTS = 'UPDATE_LS_PRODUCTS';
export const TOGGLE_MAIN_LOADER = 'TOGGLE_MAIN_LOADER';
//TODO will remove this as we don't need it.
// export const SEARCH_QUERY_PRODUCTS_COUNT = "SEARCH_QUERY_PRODUCTS_COUNT";
