import React from 'react';

const Price = ({ finalPrice, currency, format }) => {
  const price = format(finalPrice, false);

  return finalPrice !== 'undefined' ? (
    <span className="product-price">
      <span className="currency-symbol">{currency}</span>
      <span className="price-main">
        {Array.isArray(price) ? (
          <>
            {price?.[0]}
            <span class="decimal-dot">.</span>
            <span class="price-decimal">{price?.[1]}</span>
          </>
        ) : (
          price
        )}
      </span>
    </span>
  ) : null;
};

export default Price;
