import React from "react";

const HintMessage = ({show, message}) => {
    return show ? (
        <div>
            <p>{message}</p>
        </div>
    ) : null;
};

export default HintMessage;