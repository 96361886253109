import React from 'react';

const TrendingSearches = () => {
  const popularSearchTerms = window?.unbxdConfig?.popularSearchTerms;
  const popularSearchTermsArray = popularSearchTerms?.split(',').filter(term => term.trim() !== '');
  const inputBoxRef = document.getElementById('search');

  const handleViewItemClick = (value) => {
    const inputDiv = document.querySelector('.col-right');
    inputBoxRef.value = value;
    window.location.href = `/catalogsearch/result/?q=${value}`;
    inputDiv.style.display = 'none';
  };

  if (!popularSearchTermsArray?.length) {
    return null;
  }

  return (
    <div className="trending-search">
      <h5>Trending Searches</h5>
      <ul className="categories-list">
        {popularSearchTermsArray?.map((item) => (
          <li key={item} className="categories-list-item">
            <button type="button" onClick={() => handleViewItemClick(item)}>
              {item}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TrendingSearches;
