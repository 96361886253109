import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore from './store';
import Search from './containers/search';

const store = configureStore();
const App = () => <Search />;

const searchContainer = ReactDOM.createRoot(document.getElementById('search-autocomplete-container'));

if (searchContainer) {
  searchContainer.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
}
