import { validateKey, capitalize, getProductsFromLocalStrorage } from '../utils';
import {
  SEARCH_RESULT,
  SEARCH_CLEAR,
  PRODUCTS_CLEAR,
  SEARCH_QUERY,
  SEARCH_REQUEST,
  SEARCH_PRODUCTS,
  SEARCH_RESULT_ERROR,
  SUGGEST_CLICK,
  SUGGEST_REQUEST,
  SUGGEST_SUCCESS,
  SUGGEST_FAILURE,
  TOGGLE_DROPDOWN,
  UPDATE_LS_PRODUCTS,
  SEARCH_CORRECTION_SUCCESS,
  SEARCH_CORRECTIONS_STARTED,
  SEARCH_CORRECTIONS_ERROR,
  CLEAR_ACTIVE_TERM,
  TOGGLE_MAIN_LOADER,
  //TODO will remove this as we don't need it.
  //SEARCH_QUERY_PRODUCTS_COUNT,
} from '../constants/action-types';

const lsProductsInfo = getProductsFromLocalStrorage();

const filterDuplicates = (arr) => {
  const uniqueTerms = new Set();
  return arr?.filter((elem) => {
    const term = elem?.term.toLowerCase();
    const isDuplicate = uniqueTerms.has(term);
    uniqueTerms.add(term);
    return !isDuplicate;
  });
};


const initialState = {
  showMainLoader: false,
  isSearching: false,
  showDropdown: false,
  noResult: false,
  query: null,
  products: [],
  productsCount: 0,
  suggestions: {
    isInitialFetch: true,
    fetching: false,
    activeTerm: null,
    term: null,
    terms: [],
    products: {},
    redirects: {},
    error: false,
  },
  corrections: {
    terms: [],
    products: [],
    isFetchingProducts: false,
    errorMsgFetchingProducts: null,
    redirects: {},
  },
  config: window.unbxdConfig || {},
  error: false,
  errorMsg: null,
  ...lsProductsInfo,
};

const rootReducer = (state = initialState, action) => {
  let products = {};
  let activeProducts = [];
  let capitalizedActiveTerm = '';
  switch (action.type) {
    case SEARCH_CLEAR:
      const newProductLSValues = getProductsFromLocalStrorage();
      return Object.assign({}, { ...initialState, ...newProductLSValues });
    case SEARCH_QUERY:
      return Object.assign({}, state, {
        isSearching: false,
        query: action.query,
        error: false,
        errorMsg: null,
        noResult: false,
      });
    case SEARCH_REQUEST:
      return Object.assign({}, state, {
        isSearching: true,
        query: action.query,
        showDropdown: true,
      });
    case CLEAR_ACTIVE_TERM:
      return Object.assign({}, state, {
        suggestions: {
          ...state.suggestions,
          activeTerm: null,
        },
      });
    case SEARCH_RESULT:
      const allTerms = [...action.terms];
      if ('undefined' !== typeof action.payload.products) {
        let key = validateKey(action.query);
        products[key] = action.payload.products;
      }

      const suggestionsTerms = filterDuplicates([...state.suggestions?.terms, ...allTerms]);
      const correctionTerms = filterDuplicates([...state?.corrections?.terms, ...action.payload.spellCorrections]);

      return Object.assign({}, state, {
        isSearching: false,
        showDropdown: true,
        suggestions: {
          ...state.suggestions,
          terms: suggestionsTerms,
        },
        corrections: {
          ...state.corrections,
          terms: correctionTerms,
        },
      });

    case SEARCH_PRODUCTS:
      if ('undefined' !== typeof action.payload.products) {
        let key = validateKey(action.query);
        products[key] = action.payload.products;
      }

    case SEARCH_RESULT_ERROR:
      return Object.assign({}, state, {
        isSearching: false,
        error: true,
        noResult: false,
        errorMsg: action.error,
        products: [],
      });
    case PRODUCTS_CLEAR:
      return Object.assign({}, state, {
        products: [],
        suggestions: {
          ...state.suggestions,
          activeTerm: null,
        },
      });
    case SUGGEST_CLICK:
      capitalizedActiveTerm = capitalize(action.term);
      activeProducts = state.suggestions.products[capitalizedActiveTerm] || [];
      return Object.assign({}, state, {
        noResult: !activeProducts.length,
        suggestions: {
          ...state.suggestions,
          isInitialFetch: false,
          activeTerm: capitalizedActiveTerm,
        },
      });
    case SUGGEST_REQUEST:
      return Object.assign({}, state, {
        noResult: false,
        suggestions: {
          ...state.suggestions,
          isInitialFetch: action.initial,
          fetching: true,
        },
      });
    case SUGGEST_SUCCESS:
      let d = action.payload,
        redirects = state.suggestions.redirects || {};
      const capitalizedTerm = capitalize(action.term);
      const capitalizeQuery = capitalize(state.query);
      products[capitalizedTerm] = d.products;
      if (d.products.length) {
        products[capitalizedTerm] = d.products;
      }

      const suggestionTerms = filterDuplicates([
        capitalizedTerm === capitalizeQuery ? { term: capitalizedTerm, product_count: d.queryProductsCount } : {term: capitalizeQuery} ,
        ...state?.suggestions?.terms,
      ]);

      if (d.redirect) {
        redirects[capitalizedTerm] = d.redirect;
      }

      return Object.assign({}, state, {
        noResult: d.products.length < 1 && !state.suggestions.products[capitalizedTerm],
        suggestions: {
          ...state.suggestions,
          fetching: false,
          terms: suggestionTerms,
          products: {
            ...state.suggestions.products,
            ...{ [capitalizedTerm]: d.products },
          },
          redirects,
        },
        corrections: {
          ...state.corrections,
          terms: d.spellCorrections || [],
        },
      });
    case SEARCH_CORRECTION_SUCCESS:
      const getCorrectedTerm = action.payload.metaData.queryParams.q;
      const redirectLink = action.payload.redirect;
      let getRedirects = {};
      if (redirectLink) {
        getRedirects[capitalize(getCorrectedTerm)] = redirectLink;
      }
      const { products: correctedProducts } = action.payload;
      return Object.assign({}, state, {
        corrections: {
          ...state.corrections,
          isFetchingProducts: false,
          products: correctedProducts || {},
          redirects: getRedirects,
        },
      });
    case SEARCH_CORRECTIONS_STARTED:
      return Object.assign({}, state, {
        corrections: {
          ...state.corrections,
          isFetchingProducts: true,
        },
      });
    case SEARCH_CORRECTIONS_ERROR:
      return Object.assign({}, state, {
        corrections: {
          ...state.corrections,
          isFetchingProducts: false,
          errorMsgFetchingProducts: action.error,
        },
      });
    case SUGGEST_FAILURE:
      return Object.assign({}, state, {
        showDropdown: true,
        suggestions: {
          ...state.suggestions,
          error: action.error,
        },
      });
    case TOGGLE_DROPDOWN:
      return Object.assign({}, state, {
        showDropdown: Boolean(action.payload),
      });
    case UPDATE_LS_PRODUCTS:
      const updatedProducts = getProductsFromLocalStrorage();
      return Object.assign({}, state, {
        ...updatedProducts,
      });

    case TOGGLE_MAIN_LOADER: {
      const oldState = { ...state };
      const loading = action?.payload?.loading;
      return { ...oldState, showMainLoader: loading };
    }

    default:
      return state;
  }
};

export default rootReducer;
