import React from 'react';
import AutoComplete from './search/auto-complete';

const Search = () => (
  <>
    <AutoComplete />
  </>
);

export default Search;
