import { AUTOCOMPLETE_API_URL, SEARCH_API_URL, SERVER_ERROR_MSG } from '../constants/api';
import {
  SEARCH_RESULT,
  SEARCH_QUERY,
  SEARCH_REQUEST,
  SEARCH_PRODUCTS,
  SEARCH_RESULT_ERROR,
  SEARCH_CLEAR,
  PRODUCTS_CLEAR,
  SUGGEST_CLICK,
  SUGGEST_REQUEST,
  SUGGEST_SUCCESS,
  SUGGEST_FAILURE,
  TOGGLE_DROPDOWN,
  UPDATE_LS_PRODUCTS,
  SEARCH_CORRECTION_SUCCESS,
  SEARCH_CORRECTIONS_ERROR,
  SEARCH_CORRECTIONS_STARTED,
  CLEAR_ACTIVE_TERM,
  TOGGLE_MAIN_LOADER,
} from '../constants/action-types';
import { setTermsOnLocalStorage } from '../utils';
import { prepareAutoCompleteResults } from '../utils/common';
import Axios from 'axios';
const CancelToken = Axios.CancelToken;

let cancelSearch;
let cancelTerms;

export const searchQuery = (query) => {
  return (dispatch) => {
    return new Promise((res, rej) => {
      dispatch({ type: SEARCH_QUERY, query });
      res(query);
    });
  };
};

// export const queryProductsCount = (payload) => ({
//   type: SEARCH_QUERY_PRODUCTS_COUNT,
//   payload,
// });

export const clearSearch = () => {
  return (dispatch) => {
    return new Promise((res, rej) => {
      if (cancelSearch) cancelSearch();
      if (cancelTerms) cancelTerms();
      dispatch({ type: SEARCH_CLEAR });
      res();
    });
  };
};
export const clearProducts = (query) => ({ type: PRODUCTS_CLEAR, query });
export const searchRequest = (query) => ({ type: SEARCH_REQUEST, query });
export const searchCorrectionStarted = () => ({
  type: SEARCH_CORRECTIONS_STARTED,
});
export const searchCorrectionError = (error) => ({
  type: SEARCH_CORRECTIONS_ERROR,
  error,
});
export const searchResult = (query, terms, payload) => ({
  type: SEARCH_RESULT,
  query,
  terms,
  payload,
});
export const searchResultError = (error) => ({
  type: SEARCH_RESULT_ERROR,
  error,
});
export const suggestionClick = (term) => ({ type: SUGGEST_CLICK, term });
export const suggestionRequest = (term, initial) => ({
  type: SUGGEST_REQUEST,
  term,
  initial,
});
export const suggestionSuccess = (term, payload) => ({
  type: SUGGEST_SUCCESS,
  term,
  payload,
});

export const clearActiveTerm = () => ({ type: CLEAR_ACTIVE_TERM });

export const correctionProductSearchSuccesss = (term, payload) => ({
  type: SEARCH_CORRECTION_SUCCESS,
  term,
  payload,
});

export const suggestionFailure = (error) => ({ type: SUGGEST_FAILURE, error });

export const fetchSearchTerms = (query) => {
  return async (dispatch) => {
    dispatch(searchRequest(query));
    cancelTerms && cancelTerms();

    Axios.get(`${AUTOCOMPLETE_API_URL}&q=${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: new CancelToken((c) => (cancelTerms = c)),
    })
      .then(({ data }) => {
        const preparedData = prepareAutoCompleteResults(data);
        const { metaData } = preparedData || {};
        if (metaData && metaData.statusCode && metaData.statusCode === 400) {
          const msg = metaData.displayMessage || metaData.message;
          dispatch(searchResultError(msg));
        } else {
          const q = query;
          const autoSuggest = parseAutosuggestTerms(preparedData.autoSuggest) || [];
          const spellCorrections = preparedData.spellCorrections || [];
          const inFieldSuggestion = parseTerms(preparedData.inFieldSuggestion) || [];
          const brands = parseBrands(preparedData.inFieldSuggestion || [], [
            q,
            ...spellCorrections,
            ...autoSuggest,
            ...inFieldSuggestion,
          ]);
          const promotedSuggestion = parseTerms(preparedData.promotedSuggestion) || [];
          const terms = [...spellCorrections, ...promotedSuggestion, ...autoSuggest, ...inFieldSuggestion, ...brands];

          preparedData.spellCorrections = [...spellCorrections, ...promotedSuggestion, ...inFieldSuggestion];
          dispatch(searchResult(query, terms, preparedData));
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) dispatch(searchResultError('An error occurred during the request.'));
      });
  };
};

export const fetchCorrectionTermResult = (term) => {
  return (dispatch) => {
    dispatch(searchCorrectionStarted());

    Axios.get(`${SEARCH_API_URL}&q=${term}&pageSize=5`, {
      cancelToken: new CancelToken((c) => (cancelSearch = c)),
    })
      .then(({ data }) => {
        const preparedData = prepareAutoCompleteResults(data) || {};
        dispatch(correctionProductSearchSuccesss(term, preparedData));
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) dispatch(searchCorrectionError(error));
      });
  };
};

export const fetchTermResult = (term, initial = false) => {
  return (dispatch) => {
    dispatch(suggestionRequest(term, initial));
    cancelSearch && cancelSearch();
    return Axios.get(`${SEARCH_API_URL}`, {
      method: 'GET',
      cancelToken: new CancelToken((c) => (cancelSearch = c)),
      params: {
        q: term,
        pageSize: 12,
      },
    })
      .then((response) => {
        const data = response?.data;
        const preparedData = prepareAutoCompleteResults(data) || {};
        if ((preparedData.products || []).length === 0) {
          setTermsOnLocalStorage(term, false);
        }
        dispatch(suggestionSuccess(term, preparedData));
      })
      .catch((error) => {
        setTermsOnLocalStorage(term, false);
        if (!Axios.isCancel(error)) dispatch(suggestionFailure(error));
      });
  };
};

export const fetchProducts = (query) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchTermResult(query))
        .then((products) => {
          dispatch({
            type: SEARCH_PRODUCTS,
            query,
            payload: { products },
          });
          resolve(products);
        })
        .catch((error) => {
          console.error('Error fetching products:', error);
          reject(error);
        });
    });
  };
};

/**
 * @param {Array} arr
 * @returns {Array}
 */
const parseTerms = (arr) => {
  return arr?.map((term) => term?.autosuggest?.trim());
};

const parseAutosuggestTerms = (arr) => {
  return arr?.map((term) => ({ term: term?.autosuggest?.trim(), product_count: term.result_unbxd_double }));
};

/**
 * @param {Array} inFields
 * @param {Array} corrections
 * @param {String} query
 * @returns {Array}
 */
const parseBrands = (inFields, terms) => {
  let brands = [],
    suffix = terms.length ? terms[0] : '';

  for (let i = 0; i < inFields.length; i++) {
    if ('undefined' !== typeof inFields[i]['brand_in']) {
      let brandIn = inFields[i]['brand_in']
        .filter((bi) => {
          let brand = bi.trim();
          return terms.indexOf(brand) !== -1 && brand !== suffix;
        })
        .map((br) => {
          return br.trim() + ' ' + suffix;
        });

      brands = [...brands, ...brandIn];
    }
  }

  return brands;
};

export const toggleDropdown = (payload) => ({ type: TOGGLE_DROPDOWN, payload });
export const updateLSProducts = () => ({ type: UPDATE_LS_PRODUCTS });
export const toggleMainLoader = (loading) => ({
  type: TOGGLE_MAIN_LOADER,
  payload: { loading },
});
