import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ShippingLabel from '../../components/auto-suggest/product/product.shipping.label';
import { toggleDropdown, updateLSProducts } from '../../actions';
import { setLocalStorage } from '../../utils';
import { formatPrice } from '../../utils/common';
import HintMessage from '../../components/auto-suggest/hintMessage';

const RecentlyViewed = () => {
  const { productTerms, productData, recentlyViewedProduct, showDropdown, activeTerm } = useSelector((state) => state);
  const [products, setProducts] = useState([]);
  const inputBoxRef = document.getElementById('search');
  const dispatch = useDispatch();

  const handleInputClick = () => {
    if (productTerms?.length) {
      dispatch(toggleDropdown(!showDropdown));
      const rightDiv = document.querySelector('.col-right');
      if (activeTerm && activeTerm.length > 1) {
        rightDiv.style.display = 'block';
      } else {
        rightDiv.style.display = 'none';
      }
    }
  };

  const handleBoxInput = () => {
    const rightDiv = document.querySelector('.col-right');
    if (inputBoxRef.value.length > 1) {
      rightDiv.style.display = 'block';
    }
  };

  const handleStorageChange = () => {
    dispatch(updateLSProducts());
  };

  const handleOnLoad = (event) => {
    if (event.target.readyState === 'complete') {
      dispatch(updateLSProducts());
    }
  };

  const onHandleClickDelete = (i) => {
    const newItems = productTerms.filter((item) => item !== i);
    setLocalStorage('recently_searched_terms', JSON.stringify(newItems));
    dispatch(updateLSProducts());
  };

  const onHandleClickDeleteAll = () => {
    setLocalStorage('recently_searched_terms', JSON.stringify([]));
    dispatch(updateLSProducts());
  };

  const handleViewItemClick = (value) => {
    const inputDiv = document.querySelector('.col-right');
    setProducts([]);
    inputBoxRef.value = value;
    window.location.href = `/catalogsearch/result/?q=${value}`;
    inputDiv.style.display = 'none';
  };

  const updateLocalState = () => {
    const RECENTLY_VIEWED_COUNT = 5;

    const recentlyViewedProducts = Object.values(recentlyViewedProduct)
      .filter((item) => {
        const dataExists = productData.hasOwnProperty(item['product_id']);
        const currentTime = new Date();
        const productTime = new Date(item.added_at * 1000);
        const timeout = 60 * 60 * 1000; // one hour

        return dataExists && item.scope_id === window.checkout.websiteId && currentTime - productTime < timeout;
      })
      .sort((a, b) => {
        return b.added_at - a.added_at;
      })
      .map((recent) => {
        return productData[recent.product_id];
      });

    if (recentlyViewedProducts.length > RECENTLY_VIEWED_COUNT) {
      recentlyViewedProducts.length = RECENTLY_VIEWED_COUNT;
    }

    setProducts(recentlyViewedProducts);
  };

  useEffect(() => {
    updateLocalState();
    window?.addEventListener('storage', handleStorageChange, false);
    window.document?.addEventListener('readystatechange', handleOnLoad, false);

    return () => {
      window?.removeEventListener('storage', handleStorageChange, false);
      window.document?.removeEventListener('readystatechange', handleOnLoad, false);
    };
  }, []);

  useEffect(() => {
    const productCounts = Object?.keys(recentlyViewedProduct)?.length;
    const prevProductCounts = Object?.keys(recentlyViewedProduct)?.length;

    if (productCounts !== prevProductCounts) {
      updateLocalState();
    }
  }, [recentlyViewedProduct]);

  useEffect(() => {
    inputBoxRef?.addEventListener('click', handleInputClick, false);
    inputBoxRef?.addEventListener('change', handleBoxInput, false);

    return () => {
      inputBoxRef?.removeEventListener('click', handleInputClick, false);
      inputBoxRef?.removeEventListener('change', handleBoxInput, false);
    };
  });

  return (
    <div className="recently-viewed-list">
      {productTerms.length > 0 && (
        <div className="searched-terms">
          <HintMessage show={true} message="Start typing to find all tools for all trades" />
          <div className="searched">
            <h5>Recently Searched</h5>
            <div className="clear-all">
              <a onClick={onHandleClickDeleteAll}>Clear All</a>
            </div>
          </div>
          <div className="search-autocomplete-categories">
            <div className="section">
              <div className="section-content">
                <ul className="categories-list">
                  {productTerms &&
                    productTerms?.map((item) => (
                      <li key={item} className="categories-list-item">
                        <a onClick={() => handleViewItemClick(item)} className="recently-viewed-label">
                          {item}
                        </a>
                        <a className="recently-viewed-icons-close" onClick={() => onHandleClickDelete(item)}></a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {products.length > 0 && (
        <div className="recently-viewed-products">
          <h5>recently viewed products</h5>
          <div className="recently-viewd-item">
            {products.map((product, idx) => {
              const productInfoFinalPrice = formatPrice(product.price_info.final_price);
              return (
                <div key={idx} className="search-autocomplete-product" role="options" id={`options-${product.id}`}>
                  <a
                    className="search-autocomplete-hit"
                    href={product.url}
                    data-id="product_id"
                    data-name="product.name"
                    data-price="product.price_info.final_price"
                    data-position=""
                    data-brand=""
                    data-category=""
                    data-list=""
                    data-event=""
                    data-store=""
                    data-attributes="[]"
                  >
                    <div className="thumb">
                      <img
                        src={product.extension_attributes.labeled_image || product.images[0].url}
                        alt={product.name}
                      />
                    </div>
                    <div className="info">
                      <div role="title" className="product-title">
                        {product.name}
                      </div>
                      <div className="price-block">
                        {product?.extension_attributes?.not_for_sale !== true && (
                          <>
                            <span className="product-price">
                              <span className="currency-symbol">$</span>
                              <span className="price-main">
                                {Array.isArray(productInfoFinalPrice) ? (
                                  <>
                                    {productInfoFinalPrice?.[0]}
                                    <span class="decimal-dot">.</span>
                                    <span class="price-decimal">{productInfoFinalPrice?.[1]}</span>
                                  </>
                                ) : (
                                  productInfoFinalPrice
                                )}
                              </span>
                            </span>
                          </>
                        )}
                      </div>
                      <div className="product-item-actions">
                        <a
                          className="action todetails primary"
                          data-quantity={1}
                          data-category={'Search Results'}
                          data-list={'Search Results'}
                          data-event={'productClick'}
                          data-attributes={'[]'}
                        >
                          <span>View Details</span>
                        </a>
                      </div>
                      <div className="labels-wrapper">
                        <ShippingLabel label={product.shippingLabel} />
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecentlyViewed;
