import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTermsOnLocalStorage } from '../../utils';
import { suggestionClick, updateLSProducts, toggleMainLoader } from '../../actions/index';
import Spinner from '../../utils/Spinner';
import HintMessage from './hintMessage';
import ProductsCount from './productsCount';

const Categories = ({ categories, isSearchFullType }) => {
  const dispatch = useDispatch();
  const { activeTerm, fetching: isFetching, redirects } = useSelector((state) => state.suggestions);
  const redirect = redirects && redirects[activeTerm] ? redirects[activeTerm] : null;
  const [redirectValue, setRedirectValue] = useState();

  const handleClick = (e, term) => {
    e.preventDefault();
    let url;

    if (term) url = `/catalogsearch/result/?q=${encodeURIComponent(term)}`;

    dispatch(toggleMainLoader(true));
    dispatch(suggestionClick(term));
    setTermsOnLocalStorage(term);
    dispatch(updateLSProducts());
    window.location.href = url;
  };

  useEffect(() => {
    if (redirect) setRedirectValue(redirect);
  }, [redirect]);

  const renderCategories = (categories) => {
    return categories.map((cat, idx) => {
      const isActive = activeTerm ? activeTerm?.toLowerCase() == cat?.term?.toLowerCase() : false;
      return (
        <li key={idx} className={`categories-list-item`}>
          <a
            className={`category-container ${isActive ? 'active' : 'inactive'}`}
            onClick={(e) => handleClick(e, cat?.term)}
          >
            {isSearchFullType ? (
              <>
                <p>{cat.term}</p>
                <ProductsCount count={cat?.product_count} />
                {cat === activeTerm && isFetching && <Spinner style={{ marginLeft: '10px' }} />}
              </>
            ) : (
              cat.term
            )}
          </a>
        </li>
      );
    });
  };

  return (
    <>
      <div className="search-autocomplete-categories suggested-keywords">
        {isSearchFullType ? (
          <>
            <HintMessage show={true} message="Continue typing to refine search suggestions & results..." />
            <h5>suggested keywords</h5>
          </>
        ) : null}
        {categories && categories.length ? <ul className="categories-list">{renderCategories(categories)}</ul> : null}
      </div>
    </>
  );
};

export default Categories;
