import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Price from './product.price';
import Savings from './product.savings';
import ShippingLabel from './product.shipping.label';
import ProdutImage from './product.img';
import ProductLabels from './product.labels';
import { getProductPriceAttributes, formatPrice } from '../../../utils/common';
import { toggleMainLoader } from '../../../actions';

const Product = ({ data, position }) => {
  const config = useSelector((state) => state.config);
  const query = useSelector((state) => state.suggestions.activeTerm || state.query);
  const { finalPrice, storeSpecialPrice, storeOriginalPrice } = getProductPriceAttributes(data);
  const dispatch = useDispatch();

  const handleTracking = (url, ev) => {
    ev.preventDefault();
    dispatch(toggleMainLoader(true));
    if (typeof window.AEC === 'object' && window.AEC.gtm()) {
      window.dataLayer = window.dataLayer || [];
      window.AEC.click(ev.currentTarget, window.dataLayer);
      window.dataLayer.push({
        event: 'unbxdSearchQuery',
        searchQueryPayload: {
          query: query,
        },
      });
    }
    window.location = url;
  };

  const placeholderUrl = config.placeholderImgUrl || '';
  const originalPrice =
    data.typeId === 'bundle' && storeSpecialPrice
      ? finalPrice / (1 - (100 - storeSpecialPrice) / 100)
      : storeOriginalPrice;
  const typeId = data.sku.endsWith('v') ? 'configurable' : data.typeId;

  let isB2BCustomer = false;
  const mageCacheStorage = localStorage['mage-cache-storage'];
  const isForSale = data?.notForSale?.toLowerCase() !== 'true';
  const savings = originalPrice && originalPrice !== finalPrice ? originalPrice - finalPrice : false;
  const showPrice = typeof data.price !== 'undefined' && !isB2BCustomer && data?.notForSale?.toLowerCase() !== 'true';
  const showSaving =
    typeId !== 'configurable' &&
    savings &&
    data.showSavedLabel &&
    !isB2BCustomer &&
    data?.notForSale?.toLowerCase() !== 'true';
  if (typeof mageCacheStorage !== 'undefined') {
    const mageCacheObj = JSON.parse(mageCacheStorage);
    isB2BCustomer = mageCacheObj.company && mageCacheObj.company.has_customer_company;
  }

  return (
    <div className="search-autocomplete-product" role="options" id={'option-' + data.sku}>
      <a
        className="search-autocomplete-hit"
        href={data.productUrl}
        data-id={data.sku}
        data-name={data.title}
        data-price={data.price}
        data-position={position}
        data-brand={data.brand && data.brand.length ? data.brand[0] : ''}
        data-category={'Search Autosuggest'}
        data-list={'Search Autosuggest'}
        data-event={'productClick'}
        data-store={config.storeName || ''}
        data-attributes={'[]'}
        onClick={(ev) => handleTracking(data.productUrl, ev)}
      >
        <ProdutImage data={data} placeholder={placeholderUrl} />
        <div className="info">
          <div role="title" className="product-title">
            {data.title}
          </div>
          <div className="mpn">MPN: {data.partNo}</div>
          <div className="price-box">
            {showPrice && isForSale ? (
              <Price currency={config.storeCurrencySymbol || '$'} finalPrice={finalPrice} format={formatPrice} />
            ) : null}
            {showSaving && isForSale ? (
              <>
                <Savings
                  currency={config.storeCurrencySymbol || '$'}
                  savings={savings}
                  finalPrice={finalPrice}
                  originalPrice={originalPrice}
                  format={formatPrice}
                  type={data.showSavedLabel}
                />
              </>
            ) : null}
          </div>
          <div className="product-item-actions">
            <button
              className="action todetails primary"
              data-id={data.sku}
              data-name={data.title}
              data-price={data.price}
              data-quantity={1}
              data-position={position}
              data-brand={data.brand && data.brand.length ? data.brand[0] : ''}
              data-category={'Search Results'}
              data-list={'Search Results'}
              data-event={'productClick'}
              data-store={config.storeName || ''}
              data-attributes={'[]'}
              onClick={(e) => handleTracking(data.productUrl, ev)}
            >
              <span>View Details</span>
            </button>
          </div>

          <div className="labels-wrapper">
            <ShippingLabel label={data.shippingLabel} />
            {config.showProductLabels ? (
              <ProductLabels top={data.labelTop} bottom={data.labelBottom} template={config.labelsUrlTemplate} />
            ) : null}
          </div>
        </div>
      </a>
    </div>
  );
};

export default Product;
