import React from 'react';

const calculatePercentageSaved = (originalPrice, finalPrice) => {
  const difference = originalPrice - finalPrice;
  const percentageSaved = (difference / originalPrice) * 100;
  const flooredPercentage = Math.floor(percentageSaved);
  return flooredPercentage;
};

const Savings = (props) => {
  const renderLabel = () => {
    const { savings, currency, format, type, originalPrice, finalPrice } = props;
    const savingPrice = format(savings);
    return !type.match(/percentage/gi) ? (
      <>
        Save <span className="currency-symbol">{currency}</span>
        <span className="save-price wrap">
          {Array.isArray(savingPrice) ? (
            <>
              {savingPrice?.[0]}
              <span class="decimal-dot">.</span>
              <span class="you-save-decimal">{savingPrice?.[1]}</span>
            </>
          ) : (
            savingPrice
          )}
        </span>
      </>
    ) : (
      <>
        Save {''}
        {calculatePercentageSaved(originalPrice, finalPrice)}
        <span className="price-decimal">%</span>
      </>
    );
  };

  const { savings, type } = props;

  if (type.match(/(not|n't)/gi) || savings <= 0 || type.match(/do not/gi)) {
    return null;
  }

  return (
    <span className="you-save-statement">
      <span className="wrap">{renderLabel(props)}</span>
    </span>
  );
};

export default Savings;
