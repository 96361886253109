import React from "react";

const ProductLabels = ({ top, bottom, template }) => {
  const getLabelImg = (label) => {
    const url = template
      .replace("{{label_type}}", label)
      .replace("{{img_type}}", "thumbnail");

    return <img src={url} alt="Product Label" />;
  };

  return top || bottom ? (
    <span className="product-labels">
      {top ? (
        <span className="product-label label_top">{getLabelImg(top)}</span>
      ) : null}
      {bottom ? (
        <span className="product-label label_bottom">
          {getLabelImg(bottom)}
        </span>
      ) : null}
    </span>
  ) : null;
};

export default ProductLabels;
