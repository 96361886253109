import React, { useRef } from 'react';
import { getAmastyLabelStyles } from '../../../utils';
const ProdutImage = ({ data, placeholder }) => {
  const getImgUrl = (data) => {
    const attrOrder = ['labeledImage', 'thumbnail', 'smallImage'];
    const extsRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    let imgUrl;

    attrOrder.forEach((attr) => {
      if (!imgUrl && data[attr] && extsRegex.test(data[attr])) {
        imgUrl = data[attr];
        return imgUrl;
      }
    });

    if (!imgUrl && data.imageUrl && data.imageUrl.length) {
      imgUrl = data.imageUrl[0];
    }

    return imgUrl;
  };

  const { unbxdAmastyLabelTopRight, unbxdAmastyLabelTopLeft, unbxdAmastyLabelBottomRight, unbxdAmastyLabelBottomLeft } =
    data;
  const amastyLabels = [
    { label: unbxdAmastyLabelTopLeft, position: '0' },
    { label: unbxdAmastyLabelTopRight, position: '2' },
    { label: unbxdAmastyLabelBottomLeft, position: '6' },
    { label: unbxdAmastyLabelBottomRight, position: '8' },
  ];

  const imgRef = useRef(null);
  const imgUrl = getImgUrl(data);

  return (
    <div className="thumb">
      <img
        src={imgUrl}
        ref={imgRef}
        onError={() => {
          if (imgRef.current && imgRef.current.src !== placeholder) {
            imgRef.current.src = placeholder;
          }
        }}
      />
      {amastyLabels.map(({ label, position }) => {
        if (!label) return null;
        const amastyLabelStyles = getAmastyLabelStyles(position);
        return (
          <span
            key={label}
            className="amasty_label_image"
            style={{
              ...amastyLabelStyles,
              backgroundImage: `url(${label})`,
            }}
          />
        );
      })}
    </div>
  );
};

export default ProdutImage;
