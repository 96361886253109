/**
 * Replaces non alphanumeric characters with underscore.
 *
 * @param {String} key
 * @returns {String}
 */
export const validateKey = (key) => {
  return key.replace(/[^a-zA-Z0-9]/gi, '_').toLowerCase();
};

/**
 * Detects device type by screen size.
 *
 * @returns {Boolean}
 */
export const isMobile = () => {
  const w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth;

  return x < 768;
};

/**
 * Takes an array with duplicates and filter duplicates.
 *
 * @param {Array}
 * @returns {Array}
 */
export const filterDuplicates = (arr) => {
  return arr.filter((elem, idx) => {
    return arr.indexOf(elem) == idx;
  });
};

/**
 * Takes a string and returns same word with first letter capitalized.
 *
 * @param {String} str
 * @returns {String}
 */
export const capitalize = (str) => {
  return typeof str === 'string'
    ? str
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : str;
};

/**
 * Check if array has value
 */

function containsCaseInsensitive(array, value) {
  if (!Array.isArray(array)) {
    return;
  }

  // Convert the value and array elements to lowercase for case-insensitive comparison
  const lowercaseValue = value.toLowerCase();

  for (const element of array) {
    if (element.toLowerCase() === lowercaseValue) {
      return true; // Found a case-insensitive match
    }
  }

  return false; // No case-insensitive match found
}

/**
 * Set values on Localstorage
 */

export const setTermsOnLocalStorage = (term = '', hasData = true) => {
  const stKey = `recently_searched_terms`;

  let searchTerms = JSON.parse(localStorage.getItem(stKey) || '[]');
  if (!term || containsCaseInsensitive(searchTerms, term)) {
    return false;
  }

  if (hasData) {
    if (!searchTerms.includes(term)) {
      searchTerms.unshift(term);
    }
  } else {
    searchTerms = searchTerms.filter((t) => t !== term);
  }

  if (searchTerms.length > 5) {
    searchTerms.pop();
  }
  localStorage.setItem(stKey, JSON.stringify(searchTerms));
};

export const getProductsFromLocalStrorage = () => {
  let productTerms = window.localStorage.getItem('recently_searched_terms');
  let productData = window.localStorage.getItem('product_data_storage');
  let recentlyViewedProduct = window.localStorage.getItem('recently_viewed_product');

  return {
    productTerms: isJSON(productTerms) ? JSON.parse([productTerms]) : [],
    productData: isJSON(productData) ? JSON.parse([productData]) : {},
    recentlyViewedProduct: isJSON(recentlyViewedProduct) ? JSON.parse([recentlyViewedProduct]) : {},
  };
};

export const isJSON = (str) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value);
};

export const debounce = (func, delay) => {
  let timeoutId;

  return function (...args) {
    const context = this;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export const getAmastyLabelStyles = (labelPosition) => {
  const commonStyles = {
    position: 'absolute',
    width: '45%',
    height: '45%',
    zIndex: '1',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  };
  const commonTopStyles = {
    ...commonStyles,
    top: 0,
    backgroundPosition: 'top',
  };
  const commonBottomStyles = {
    ...commonStyles,
    bottom: 0,
    backgroundPosition: 'bottom',
  };
  switch (labelPosition) {
    case '0':
      return { ...commonTopStyles, left: 0 };
    case '2':
      return { ...commonTopStyles, right: 0 };
    case '6':
      return { ...commonBottomStyles, left: 0 };
    case '8':
      return { ...commonBottomStyles, right: 0 };
    default:
      return { ...commonTopStyles, left: 0 };
  }
};
