import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Categories from '../../components/auto-suggest/categories';
import HammerSpinner from '../../utils/HammerSpinner';
import { clearProducts, toggleMainLoader, fetchProducts } from '../../actions';
import { MainLoader } from '../../components/mainLoader';
import Error from '../../components/auto-suggest/error';
import Products from '../../components/auto-suggest/products';
import NoResult from '../../components/auto-suggest/no-result';
import RecentlyViewed from './recently-viewed';
import HintMessage from '../../components/auto-suggest/hintMessage';
import { setTermsOnLocalStorage, capitalize } from '../../utils';

import SearchBox from './search-box';
import TrendingSearches from './trendingSearches';
const searchForm = document.getElementById('search_mini_form');
const AutoComplete = () => {
  const dropDownRef = useRef();
  const mainContainer = document.getElementById('minisearch-control');
  const outerMostContainer = document.getElementById('search-main');

  const bodyElement = document.body;

  const [productsData, setProductsData] = useState([]);
  const [showMainLoaderMask, setShowMainLoaderMask] = useState(false);
  const [showRightCol, setShowRightCol] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const isDesktop = window.innerWidth > 767;

  const dispatch = useDispatch();
  const {
    query,
    isSearching,
    noResult,
    error,
    errorMsg,
    productTerms,
    suggestions: suggestions_,
    corrections: { terms: correctionTerms },
  } = useSelector((state) => state);

  const { isInitialFetch, fetching: isFetching, terms: suggestions, activeTerm, redirects, products } = suggestions_;
  const productsQuery = query?.slice(0, -1);
  const capitalizedProductQuery = capitalize(productsQuery);

  useEffect(() => {
    if (redirect) setRedirectUrl(redirect);
    else setRedirectUrl('');
  }, [activeTerm, Object.keys(redirects).length]);

  useEffect(() => {
    if (redirectUrl) dispatch(fetchProducts(productsQuery));
  }, [redirectUrl]);

  const handleFocus = () => {
    if (!openDropDown) {
      setOpenDropDown(true);
    }
    mainContainer?.classList?.add('active');
    outerMostContainer?.classList?.add('active');
    bodyElement?.classList?.add('search-is-active');
  };

  const handlePressEnter = useCallback(
    (event) => {
      console.log('handlePressEnter , Called Focus OUtInput');
      let eventKeyPressed = event?.keyCode;
      if (eventKeyPressed === 13) {
        let url = `/catalogsearch/result/?q=${encodeURIComponent(event?.target.value)}`;
        if (redirectUrl) {
          url = redirectUrl;
        }
        if (url) {
          if (isDesktop) {
            mainContainer?.classList?.remove('active');
            outerMostContainer?.classList?.remove('active');
          }
          setOpenDropDown(false);
          dispatch(toggleMainLoader(true));
          setTermsOnLocalStorage(event?.target.value);
          window.location.href = url;
        }
      }
    },
    [isDesktop, redirectUrl],
  );

  useEffect(() => {
    const outerInputElement = document.getElementById('search');
    outerInputElement?.addEventListener('focus', handleFocus);
    outerInputElement?.addEventListener('keyup', handlePressEnter);
    document.addEventListener('mousedown', handleClick, false);
    bodyElement?.classList?.add('search-type-full');
    return () => {
      if (outerInputElement) {
        outerInputElement?.removeEventListener('focus', handleFocus);
        outerInputElement?.removeEventListener('keyup', handlePressEnter);
      }
      document.removeEventListener('mousedown', handleClick, false);
      bodyElement?.classList?.remove('search-type-full');
    };
  }, []);

  useEffect(() => {
    const activeProducts = redirectUrl ? products[capitalizedProductQuery] : products[activeTerm];
    setShowRightCol(false);
    if (activeTerm && !isFetching) {
     if (activeProducts?.length || correctionTerms?.length) {
        setShowRightCol(true);
        setProductsData(activeProducts);
      }
    }
  }, [isFetching, products, activeTerm, isInitialFetch, correctionTerms?.length, suggestions.length]);

  const handleClick = (e) => {
    if (
      searchForm &&
      dropDownRef &&
      dropDownRef.current &&
      !searchForm.contains(e.target) &&
      !dropDownRef.current.contains(e.target)
    ) {
      setOpenDropDown(false);
      mainContainer?.classList?.remove('active');
      outerMostContainer?.classList?.remove('active');
      bodyElement?.classList?.remove('search-is-active');
    }
  };

  const handleCloseBtnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDropDown(false);
    mainContainer?.classList?.remove('active');
    outerMostContainer?.classList?.remove('active');
    bodyElement?.classList?.remove('search-is-active');
  };

  const redirect = redirects && redirects[activeTerm] ? redirects[activeTerm] : null;
  const shouldRecentlyItemSHow = !isSearching && !isFetching && !suggestions.length && !query;
  const dropDownOpen = suggestions.length > 0 || productTerms?.length > 0;

  return (
    <div
      className={`new-search-autocomplete search-autocomplete-dropdown ${openDropDown ? 'open' : ''}`}
      ref={dropDownRef}
    >
      <div className="close-btn-wrap">
        <button className="close-search-btn" type="button" onClick={(e) => handleCloseBtnClick(e)}>
          close
        </button>
      </div>
      <div className="search-wrapper">
        <SearchBox isFocused={openDropDown} setShowMainLoaderMask={setShowMainLoaderMask} isSearchFullType />
      </div>
      <MainLoader showMainLoaderMask={showMainLoaderMask} />
      <div className={`search-autocomplete-wrapper  search-result`}>
        <div className="col-left" aria-busy={isSearching}>
          {!dropDownOpen && !activeTerm && (
            <div className="search-autocomplete-categories suggested-keywords">
              <HintMessage show={true} message="Start typing to find all tools for all trades" />
              <div className="searched">
                <h5>Recently Searched</h5>
                <p>No recent search</p>
              </div>
            </div>
          )}
          {shouldRecentlyItemSHow && <>
          <RecentlyViewed />
          <TrendingSearches />
          </>}
          {suggestions?.length ? <Categories categories={suggestions} isSearchFullType /> : null}

          <HammerSpinner show={isSearching} />
        </div>
        <div
          className={`col-right ${activeTerm ? 'show' : ''} ${products && products[activeTerm] ? 'search-result' : ''}`}
          aria-busy={isFetching}
          style={{
            display: showRightCol ? 'block' : 'none',
          }}
        >
          <span className="overlay" onClick={() => dispatch(clearProducts())}></span>
          <NoResult show={noResult} query={query} activeTerm={activeTerm} />
          <Error error={error} query={query} errorMsg={errorMsg} />
          {!noResult ? (
            <Products products={productsData} activeTerm={activeTerm} redirects={redirects} redirect={redirect} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AutoComplete;
