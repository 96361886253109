import React from "react";
import { HAMMER_SPINNER_BASE64 } from "./constants";

const HammerSpinner = ({ show, message }) =>
    show ? (
        <div className="instant-search-loader">
            <img src={HAMMER_SPINNER_BASE64} alt="loading" />
            {message && <span>{message}</span>}
        </div>
    ) : null;

export default HammerSpinner;
