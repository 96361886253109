import React, { useEffect } from 'react';

import SearchInput from '../../components/search-box/search-input';

const searchControl = document.getElementById('minisearch-control'),
  searchLabel = document.getElementById('minisearch-label');

const SearchBox = (props) => {
  useEffect(() => {
    document.addEventListener('mousedown', toggleMobileSearch, false);
    return () => document.removeEventListener('mousedown', toggleMobileSearch, false);
  }, []);

  const toggleMobileSearch = (e) => {
    if (searchLabel && e.target.contains(searchLabel)) {
      searchControl && searchControl.classList.toggle('opened');
    }
  };
  return <SearchInput {...props} />;
};

export default SearchBox;
