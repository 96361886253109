const unbxdConfig = window?.unbxdConfig;
export const AUTOCOMPLETE_API_URL = unbxdConfig?.autoCompleteUrl;
export const AUTOSUGGEST_API_URL = unbxdConfig?.autoSugguestUrl;
export const SEARCH_API_URL = unbxdConfig?.searchUrl;
export const SERVER_ERROR_MSG = 'Oops! Something went wrong while searching for {{%q}}. Please try again.';
const STORE_DEFAULT = 'Default Store View';
const STORE_QUEENSLAND = 'Queensland Store View';
const STORE_SOUTH_AUS = 'South Australia Store View';
const STORE_WEST_AUS = 'Western Australia Store View';
export const PRICE_FIELD = 'price';
export const SPECIAL_PRICE_FIELD = 'specialPrice';
export const ORIGINAL_PRICE_FIELD = 'originalPrice';
export const STORES_ENUM = {
  [STORE_DEFAULT]: 1,
  [STORE_QUEENSLAND]: 2,
  [STORE_SOUTH_AUS]: 3,
  [STORE_WEST_AUS]: 4,
};
